<template>
  <b-row>
    <b-col cols="12">
      <b-card class="match-height">
        <b-row>
          <b-col class="rounded">
            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <p class="mb-1 summery-text">Employee List</p>
              <div>
                <label for="" class="label-font">Search:</label>
                <b-form-group>
                  <b-form-input
                    @keyup="$emit('childClick')"
                    v-model="query.name"
                    class="rounded search-input bg-light"
                    placeholder="Search..."
                    type="search"
                    size="md"
                  />
                </b-form-group>
              </div>
            </div>
            <b-table small :fields="fields" :items="employeeList" responsive>
              <template #cell(image)="data">
                <img
                  :src="
                    data.item['avatar'] &&
                    data.item['avatar'].includes('https://ui-avatars.com')
                      ? data.item['avatar']
                      : $store.state.appConfig.imageBaseUrl +
                        data.item['avatar']
                  "
                  height="52"
                  width="52"
                  alt="avatar"
                  class="rounded-circle"
                />
              </template>
              <template #cell(name)="data">
                {{ data.item["name"] }}
              </template>
              <template #cell(field)="data">
                {{ data.item["sector"] ? data.item["sector"].name : "N/A" }}
              </template>
              <template #cell(experiance)="data">
                {{ data.item["email"] }}
              </template>
            </b-table>
            <b-pagination
              class="mt-3 p-1 d-flex justify-content-end bg-white"
              v-if="total > query.per_page"
              v-model="query.page"
              :total-rows="total"
              :per-page="query.per_page"
              aria-controls="my-table"
              @change="getEmployees($event)"
              pills
              size="lg"
              page-class="info"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
BBadge,
BButton,
BCard,
BCol,
BFormGroup,
BFormInput,
BPagination,
BRow,
BTable,
} from "bootstrap-vue";

export default {
  props: {
    employeeList: {
      type: Array,
      default: () => [],
    },
    query: {
      type: Object,
      default: () => {},
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  components: {
    BRow,
    BCard,
    BBadge,
    BButton,
    BCol,
    BTable,
    BPagination,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      fields: [
        { key: "image", label: "avatar" },
        { key: "name", label: "name" },
        { key: "field", label: "EXPERTISE" },
        { key: "experiance", label: "email" },
      ],
    };
  },
};
</script>

<style scoped>
.summery-card {
  width: 271px;
  height: 358px;
  background: #e7edff;
  border-radius: 24px;
}

.summery-number {
  width: 141px;
  height: 141px;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  line-height: 86px;
  text-align: center;

  /* Blue Soft */

  color: #7190ef;
}

.summery-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  /* identical to box height */

  text-align: center;

  /* Blue Soft */

  color: #7190ef;
}
</style>
