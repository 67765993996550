<template>
  <div class="d-flex flex-row">
    <b-container
      class="bg-white shadow-md rounded-lg d-flex flex-column justify-content-start"
    >
      <b-row
        class="d-flex flex-row justify-content-between align-items-center p-2"
      >
        <h1>Holidays</h1>
      </b-row>
      <b-col
        v-for="item in 3"
        :key="item"
        class="m-0 p-0"
      >
        <b-row
          class="d-flex align-items-center bg-light p-1 mb-1 border-bottom"
        >
          <b-col
            class="d-flex flex-row justify-content-between align-items-center"
          ><img
             src="@/assets/images/avatars/12-small.png"
             height="52"
             width="52"
             alt="avatar"
             class="rounded-circle"
           >
            <div class="d-flex flex-column justify-content-start">
              <p class="text-left m-0 px-1">
                <a href="#">Inflack Limited</a> requested for 4 sweepers
              </p>
              <span class="text-left px-1">4 hrs ago</span>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-row class="d-flex justify-content-center align-items-center">
        <button class="show-all  m-1 btn">
          <b>Show All</b>
        </button>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
BCalendar, BCol, BContainer, BRow
} from 'bootstrap-vue';

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BCalendar,
  },
}
</script>

<style scoped>
.sum-font {
  font-family: Segoe UI;
  font-size: 64px;
  font-weight: 900;
  line-height: 86px;
  letter-spacing: 0em;
  text-align: center;
  color: #7190ef;
}
.circle-size {
  width: 141px;
  height: 141px;
}
.card-background {
  width: 271px;
  height: 358px;
  left: 0px;
  top: 0px;
  background: #e7edff;
}
.title-font {
  color: #7190ef;
}

.sum-font-two {
  font-family: Segoe UI;
  font-size: 64px;
  font-weight: 900;
  line-height: 86px;
  letter-spacing: 0em;
  text-align: center;
  color: #2dd3a1;
}
.card-background-two {
  width: 271px;
  height: 358px;
  left: 0px;
  top: 0px;

  background: #dcf4e4;
  border-radius: 24px;
}
.title-font-two {
  color: #2dd3a1;
}
.employee-name {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */

  /* Text Additional */

  color: #79869f;
}
.show-all {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
  color: white;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
}
</style>
