<template>
  <b-card>
    <div class="row">
      <div class="col-12 col-md-3">
        <div
          class="d-flex flex-column align-items-center justify-content-center summery-card employee-card"
        >
          <router-link
            class="d-flex flex-column align-items-center"
            to="/employees"
          >
            <div class="bg-white rounded-circle summery-div">
              <h1 class="summery-number">{{ dashboardData.employee }}</h1>
            </div>
            <p class="m-0 text-center summery-text">Employees</p>
          </router-link>
        </div>
      </div>
      <div class="col-12 col-md-3">
        <div
          class="d-flex flex-column align-items-center justify-content-center summery-card approve-card"
        >
          <router-link
            class="d-flex flex-column align-items-center"
            to="/published-roster"
          >
            <div class="bg-white rounded-circle  summery-div">
              <h1 class="summery-number">{{ dashboardData.rosters }}</h1>
            </div>
            <p class="m-0 text-center summery-text">Approved Rosters</p>
          </router-link>
        </div>
      </div>
      <div class="col-12 col-md-3">
        <div
          class="d-flex flex-column align-items-center justify-content-center summery-card unapprove-card"
        >
          <router-link
            class="d-flex flex-column align-items-center"
            to="/time-sheets?id=1&name=Approved"
          >
            <div class="bg-white rounded-circle summery-div">
              <h1 class="summery-number">{{ dashboardData.timesheet_pending }}</h1>
            </div>
            <p class="m-0 text-center summery-text">Unapproved Timesheet</p>
          </router-link>
        </div>
      </div>
      <div class="col-12 col-md-3">
        <div
          class="d-flex flex-column align-items-center justify-content-center summery-card job-card"
        >
          <router-link
            class="d-flex flex-column align-items-center"
            to="/job-posts"
          >
            <div class="bg-white rounded-circle summery-div">
              <h1 class="summery-number">{{ dashboardData.jobs }}</h1>
            </div>
            <p class="m-0 text-center summery-text">Open Jobs</p>
          </router-link>
        </div>
      </div>
    </div>
  </b-card>
</template>
<script>
import { BBadge, BButton, BCard, BCol, BLink, BRow } from "bootstrap-vue";

export default {
  props: {
    dashboardData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BRow,
    BCard,
    BBadge,
    BButton,
    BCol,
    BLink,
  },
};
</script>

<style scoped>
.summery-card {
  width: 100%;
  height: 200px;
  border-radius: 24px;
}

.employee-card{
  background: #01185E;
}
.employee-card .summery-number {
  color: #01185E;
}

.approve-card {
  background: green;
}
.approve-card .summery-number {
  color: green;
}

.unapprove-card {
  background: orange;
}

.unapprove-card .summery-number {
  color: orange;
}

.job-card {
  background: gray;
}
.job-card .summery-number {
  color: gray;
}


@media only screen and (max-width: 1440px) and (min-width: 1050px) {
  .summery-card {
    /* display: none !important; */
    width: 100%;
    height: 180px;
    border-radius: 24px;
  }

  .summery-number {
    font-family: "Segoe UI";
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 86px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .summery-text {
    font-family: "Segoe UI";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 32px;
    text-align: center;
    color: #fff !important;
  }
}

.summery-number {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 86px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.summery-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 32px;
  text-align: center;
  color: #fff !important;
}

.summery-div {
  position: relative;
  width: 120px;
  height: 120px;
}
</style>
