<template>
  <b-card class="overflow-scroll">
    <b-calendar
      v-model="value"
      block
      locale="en-US"
      class="calendar"
    />
    <dashboardHomeNotificationCardVue />
  </b-card>
</template>

<script>
import {
BCalendar, BCard, BCol, BContainer, BRow
} from 'bootstrap-vue'
import dashboardHomeNotificationCardVue from './customer-notification-card.vue'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BCalendar,
    BCard,
    dashboardHomeNotificationCardVue,
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    // 15th two months prior
    const minDate = new Date(today)
    minDate.setMonth(minDate.getMonth() - 2)
    minDate.setDate(15)
    // 15th in two months
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth() + 2)
    maxDate.setDate(15)

    return {
      value: '',
      min: minDate,
      max: maxDate,
    }
  },
  mounted() {
    this.setToday()
  },
  methods: {
    setToday() {
      const now = new Date()
      this.value = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    },
  },
}
</script>
<style>
.calendar {
}
</style>
