<template>
  <div class="card" v-if="employees.length > 0">
    <div class="row m-1 my-2">
      <div class="col-sm-12">
        <h4 class="mb-1">Employee's Upcomming Document Expiry</h4>
        <table class="table table-bordered p-2">
          <thead>
            <tr>
              <th rowspan="2">SL</th>
              <th rowspan="2">Employee</th>
              <th rowspan="2">Visa Expiry</th>
              <th rowspan="2">Passport Expiry</th>
              <th rowspan="2">Driving License Expiry</th>
              <th colspan="2" class="text-center">Certificate</th>
              <th rowspan="2">Action</th>
            </tr>
            <tr>
              <th>Name</th>
              <th>Expiry (Days)</th>
            </tr>
          </thead>
          <tbody id="rows">
            <tr
              :class="getExpiry(employee)"
              v-for="(employee, index) in employees"
              :key="index"
            >
              <td>
                <span>{{ from + index }}</span>
              </td>
              <td>
                <p>{{ employee.name }}</p>
              </td>
              <td>
                <p v-if="employee.visa > 0">{{ employee.visa }} Days Left</p>
                <p v-else-if="employee.visa === 0">Today</p>
                <p v-else-if="employee.visa < 0">{{ Math.abs(employee.visa) }} Days Ago</p>
              </td>
              <td>
                <p v-if="employee.passport > 0">{{ employee.passport }} Days Left</p>
                <p v-else-if="employee.passport === 0">Today</p>
                <p v-else-if="employee.passport < 0">{{ Math.abs(employee.passport) }} Days Ago</p>
              </td>
              <td>
                <p v-if="employee.driving > 0">{{ employee.driving }} Days Left</p>
                <p v-else-if="employee.driving === 0">Today</p>
                <p v-else-if="employee.driving < 0">{{ Math.abs(employee.driving) }} Days Ago</p>
              </td>
              <td>
                <p
                  class="custom-border"
                  v-for="(certificate, index) in employee.certificate" :key="index"
                >
                  {{ certificate.name }}
                  <br />
                </p>
              </td>
              <td>
                <div
                  class="custom-border"
                  v-for="(certificate, index) in employee.certificate" :key="index"
                >
                  <p v-if="certificate.rem_days > 0">{{ certificate.rem_days }} Days Left</p>
                  <p v-else-if="certificate.rem_days === 0"> Today</p>
                  <p v-else-if="certificate.rem_days < 0">{{ certificate.rem_days }} Days Ago</p>
                </div>
              </td>
              <td>
                <span>
                  <button class="btn btn-sm btn-primary">
                    <router-link
                      :to="'/employee-preview/' + employee.id"
                      class="text-white"
                      >View</router-link
                    >
                  </button>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <b-pagination
          class="mt-3 p-1 d-flex justify-content-end bg-white"
          v-if="total > query.per_page"
          v-model="query.page"
          :total-rows="total"
          :per-page="query.per_page"
          aria-controls="my-table"
          @change="getEmployeesExpiry($event)"
          pills
          size="lg"
          page-class="info"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { BPagination } from "bootstrap-vue";
export default {
  data() {
    return {
      employees: [],
      total: 0,
      from: 0,
      query: {
        page: 1,
        per_page: 20,
      },
    };
  },
  components: {
    BPagination,
  },
  mounted() {
    this.getEmployeesExpiry();
  },
  methods: {
    getEmployeesExpiry(e) {
      if (e && typeof e === "number") {
        this.query.page = e;
      } else {
        this.query.page = 1;
      }
      this.$store
        .dispatch("dashboard/getEmployeesExpiry", this.query)
        .then((response) => {
          this.employees = response.data.data;
          this.total = response.data.total;
          this.from = response.data.from;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getExpiry(employee) {
      let data = [
        employee.visa,
        employee.passport,
        employee.driving,
        ...employee.certificate.map((certificate) => certificate.rem_days),
      ];
      // let data = [40, 80]
      let min = Math.min(...data);
      // 0 to 30 days - red
      // 31 to 90 days - yellow
      // 91 to 365 days - green
      if (min <= 30) {
        return "table-danger";
      } else if (min <= 90) {
        return "table-warning";
      } else {
        return "table-success";
      }
    },
  },
};
</script>

<style scoped>
table td {
  vertical-align: middle !important;
  padding: 0 !important;
}
table td span {
  padding: 0.5rem !important;
}
table td p {
  margin: 0;
  padding: 0.5rem !important;
}
.custom-border {
  width: 100%;
  display: inline-block;
}
.table-danger td .custom-border {
  border-bottom: 1px solid #f4a6a7;
}

.table-warning td .custom-border {
  border-bottom: 1px solid #ffcd9d;
}
.custom-border:last-child {
  border-bottom: none !important;
}

th {
  border-color: #b9b6c1 !important;
}

[dir] .table-bordered thead th, [dir] .table-bordered thead td {
    border-bottom-width: 1px !important;
}
/* p {
  margin: 0;
  padding: 0;
  #b9b6c1
} */
</style>