<template>
  <b-row>
    <b-col cols="12">
      <b-alert v-if="status == 0 && user.roles[0].name === 'superadmin'" show variant="danger p-1">
        <b>Xero is not connected with your system! Please connect to xero for timesheet related tasks.</b>
      </b-alert>
      <b-row>
        <b-col>
          <summery :user="user" />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col cols="12" lg="6">
          <dash-board-home-line-chart />
        </b-col>
        <b-col cols="12" lg="6">
          <dashboardHomeStatus />
        </b-col>
        <b-col cols="12">
          <documentExpireTable />
        </b-col>
        <b-col cols="12" class="mt-2">
          <sisterConcerns v-if="user.roles[0].name === 'superadmin'" />
          <customerCompany v-if="user.roles[0].name === 'admin'" />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { getLogedInUser } from "@/utils/auth";
import {
  BCol,
  BRow,
  BAlert
} from "bootstrap-vue";
import customerCompany from "./customerCompany.vue";
import dashBoardHomeLineChart from "./dashboard-home-line-chart.vue";
import dashboardHomeStatus from "./dashboard-home-status.vue";
import sisterConcerns from "./sisterConcerns.vue";
import summery from "./summery.vue";
import documentExpireTable from "./document-expire-table.vue";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    summery,
    dashBoardHomeLineChart,
    dashboardHomeStatus,
    sisterConcerns,
    customerCompany,
    documentExpireTable,
  },
  data() {
    return {
      user: JSON.parse(getLogedInUser()),
      status: 0,
    };
  },
  mounted() {
    if (this.user.roles[0].name === 'superadmin'){
      this.getXeroServiceStatus();
    }
  },
  methods: {
    getXeroServiceStatus() {
      this.$store
        .dispatch("globalConfig/getXeroServiceStatus")
        .then((res) => {
          if (res.status_code == 200) {
            this.status = res.data.status;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
