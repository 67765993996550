<template>
  <div>
    <b-card class="expiary-card" v-if="hasExpire">
      <div class="row">
        <b-col cols="12">
          <h3 class="summery-text">Important Documents Expiry</h3>
        </b-col>
        <div v-if="passport.days_left <= 90" class="col-12 col-md-4">
          <b-card
            class="expiary-card"
            v-if="passport"
            :class="getValidationClass(passport.days_left)"
            @click="goToProfileUpdatePage('passport')"
          >
            <div class="row">
              <div class="col-2">
                <img
                  src="@/assets/images/icons/passport-white.png"
                  class="summery-icon"
                />
              </div>
              <div class="col-10">
                <h3>Passport Information</h3>
                <div v-if="passport.expire_date">
                  <div class="row w-100 mt-1">
                    <div class="col-6"><b>Expiry Date</b></div>
                    <div class="col-1 px-0"><b>:</b></div>
                    <div class="col-5 px-0">
                      <b>{{ formateDate(passport.expire_date) }}</b>
                    </div>
                  </div>
                  <div class="row w-100 mt-1">
                    <div class="col-6">
                      <b v-if="dateIsBeforeToday(passport.expire_date)"
                        >Day Left</b
                      >
                      <b v-else>Expired</b>
                    </div>
                    <div class="col-1 px-0"><b>:</b></div>
                    <div class="col-5 px-0">
                      <b
                        >{{ Math.abs(passport.days_left) }} Days
                        <span v-if="!dateIsBeforeToday(passport.expire_date)"
                          >Ago</span
                        ></b
                      >
                    </div>
                  </div>
                </div>
                <div v-else>
                  <p>Please Add Passport Infomation</p>
                </div>
              </div>
            </div>
          </b-card>
        </div>
        <div v-if="visa.days_left <= 90" class="col-12 col-md-4">
          <b-card
            class="expiary-card"
            v-if="visa"
            :class="getValidationClass(visa.days_left)"
            @click="goToProfileUpdatePage('visa')"
          >
            <div class="row">
              <div class="col-2">
                <img
                  src="@/assets/images/icons/visa-white.png"
                  class="summery-icon"
                />
              </div>
              <div class="col-10">
                <h3>Visa Information</h3>
                <div v-if="visa.expire_date">
                  <div class="row w-100 mt-1">
                    <div class="col-6"><b>Expiry Date</b></div>
                    <div class="col-1 px-0"><b>:</b></div>
                    <div class="col-5 px-0">
                      <b>{{ formateDate(visa.expire_date) }}</b>
                    </div>
                  </div>
                  <div class="row w-100 mt-1">
                    <div class="col-6">
                      <b v-if="dateIsBeforeToday(visa.expire_date)">Day Left</b>
                      <b v-else>Expired</b>
                    </div>
                    <div class="col-1 px-0"><b>:</b></div>
                    <div class="col-5 px-0">
                      <b
                        >{{ Math.abs(visa.days_left) }} Days
                        <span v-if="!dateIsBeforeToday(visa.expire_date)"
                          >Ago</span
                        ></b
                      >
                    </div>
                  </div>
                </div>
                <div v-else>
                  <p>Please Add Visa Infomation</p>
                </div>
              </div>
            </div>
          </b-card>
        </div>
        <div v-if="driving.days_left <= 90" class="col-12 col-md-4">
          <b-card
            class="expiary-card"
            v-if="driving"
            :class="getValidationClass(driving.days_left)"
            @click="goToProfileUpdatePage('driving')"
          >
            <div class="row">
              <div class="col-2">
                <img
                  src="@/assets/images/icons/driving-white.png"
                  class="summery-icon"
                />
              </div>
              <div class="col-10">
                <h3>Driving License Information</h3>
                <div v-if="driving.expire_date">
                  <div class="row w-100 mt-1">
                    <div class="col-6"><b>Expiry Date</b></div>
                    <div class="col-1 px-0"><b>:</b></div>
                    <div class="col-5 px-0">
                      <b>{{ formateDate(driving.expire_date) }}</b>
                    </div>
                  </div>
                  <div class="row w-100 mt-1">
                    <div class="col-6">
                      <b v-if="dateIsBeforeToday(driving.expire_date)"
                        >Day Left</b
                      >
                      <b v-else>Expired</b>
                    </div>
                    <div class="col-1 px-0"><b>:</b></div>
                    <div class="col-5 px-0">
                      <b
                        >{{ Math.abs(driving.days_left) }} Days
                        <span v-if="!dateIsBeforeToday(driving.expire_date)"
                          >Ago</span
                        ></b
                      >
                    </div>
                  </div>
                </div>
                <div v-else>
                  <p>Please Add Driving License Infomation</p>
                </div>
              </div>
            </div>
          </b-card>
        </div>
        <div
          v-for="(item, index) in certificate"
          :key="index"
          class="col-12 col-md-4"
        >
          <b-card
            class="expiary-card"
            v-if="item"
            :class="getValidationClass(item.days_left)"
            @click="goToProfileUpdatePage('certificate')"
          >
            <div class="row">
              <div class="col-2">
                <img
                  src="@/assets/images/icons/certificate-white.png"
                  class="summery-icon"
                />
              </div>
              <div class="col-10">
                <h3>{{ item.name }}</h3>
                <div class="row w-100 mt-1">
                  <div class="col-6"><b>Expiry Date</b></div>
                  <div class="col-1 px-0"><b>:</b></div>
                  <div class="col-5 px-0">
                    <b>{{ formateDate(item.expire_date) }}</b>
                  </div>
                </div>
                <div class="row w-100 mt-1">
                  <div class="col-6">
                    <b v-if="dateIsBeforeToday(item.expire_date)">Day Left</b>
                    <b v-else>Expired</b>
                  </div>
                  <div class="col-1 px-0"><b>:</b></div>
                  <div class="col-5 px-0">
                    <b
                      >{{ Math.abs(item.days_left) }} Days
                      <span v-if="!dateIsBeforeToday(item.expire_date)"
                        >Ago</span
                      ></b
                    >
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import { BBadge, BButton, BCard, BCol, BRow } from "bootstrap-vue";
import moment from "moment";
import { getLogedInUser } from "@/utils/auth";
export default {
  props: {
    passport: {
      type: Object,
      default: () => ({}),
    },
    visa: {
      type: Object,
      default: () => ({}),
    },
    driving: {
      type: Object,
      default: () => ({}),
    },
    certificate: {
      type: Array,
      default: () => [],
    },
    hasExpire: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    BRow,
    BCard,
    BBadge,
    BButton,
    BCol,
  },
  data() {
    return {
      logedInUser: JSON.parse(getLogedInUser()),
    };
  },

  methods: {
    getValidationClass(day) {
      if (day > 90) {
        return "bg-success text-white";
      } else if (day > 30) {
        return "amber-bg text-white";
      } else {
        return "bg-custom text-white";
      }
    },
    formateDate(date) {
      // format date to dd-mm-YYYY
      return moment(date).format("DD-MM-YYYY");
    },
    dateIsBeforeToday(date) {
      const today = moment();
      const expireDate = moment(date);
      const diff = expireDate.diff(today, "days");
      if (diff > -1) {
        return true;
      } else {
        return false;
      }
    },
    goToProfileUpdatePage(type) {
      this.$router.push({
        name: "employee-profile-update",
        params: { id: this.logedInUser.id },
        query: { type: type },
      });
    },
  },
};
</script>

<style scoped>
.summery-card {
  background: #e7edff;
  border-radius: 24px;
}
.summery-number {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 900;
  font-size: 15px;
  line-height: 86px;
  text-align: center;
  color: #7190ef;
}
.summery-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #7190ef;
  margin-bottom: 25px;
}
h3 {
  color: white;
}
.card.expiary-card.bg-custom.text-white {
  height: 160px;
}
.card.expiary-card.amber-bg.text-white {
  height: 160px;
}

.bg-custom {
  background: #ea5455;
}

.amber-bg {
  background: #ffa500;
}
.expiary-card {
  cursor: pointer;
}
</style>
