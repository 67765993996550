<template>
  <section>
    <b-row v-if="rosterWorks.length > 0">
      <b-col cols="12">
        <b-card>
          <div
            class="d-flex flex-row justify-content-between align-items-center mb-1"
          >
            <p class="summery-text mb-0">Unapproved Timesheets</p>
            <router-link
              v-if="rosterWorks.length > 0"
              to="/time-sheets"
              class="py-2"
              >View All</router-link
            >
          </div>
          <div v-if="rosterWorks.length > 0">
            <div class="row">
              <div
                class="col-md-4"
                v-if="logedInUser.roles[0].name !== 'employee'"
              >
                <label for="" class="label-font">Search:</label>
                <b-form-group class="border rounded">
                  <b-form-input
                    v-debounce:300="getRosterWorkList"
                    v-model="query.employee"
                    class="rounded"
                    placeholder="Search by employee name"
                    type="search"
                    size="md"
                  />
                </b-form-group>
              </div>
            </div>

            <div
              v-if="loading"
              class="d-flex justify-content-center align-items-center vh-100 mb-3"
            >
              <b-spinner label="Loading..."></b-spinner>
            </div>
            <div class="card" v-else>
              <time-sheet-table-component
                :roster-works="rosterWorks"
                :fields="fields"
                :total="total"
                :form="form"
              />
            </div>
            <b-pagination
              class="mt-5 p-1 d-flex justify-content-end bg-white"
              v-if="total > query.per_page"
              v-model="query.page"
              :total-rows="total"
              :per-page="query.per_page"
              aria-controls="my-table"
              @change="getRosterWorkList($event)"
              pills
              size="lg"
              page-class="info"
            ></b-pagination>
          </div>
          <div v-else>
            <p class="text-center">No Data Found!</p>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import TimeSheetTableComponent from "@/components/timesheet/TimeSheetTableComponent.vue";
import { getLogedInUser } from "@/utils/auth";
import {
BButton,
BCard,
BCol,
BFormGroup,
BFormInput,
BFormTextarea,
BPagination,
BRow,
BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BPagination,
    BCard,
    vSelect,
    TimeSheetTableComponent,
  },

  data() {
    return {
      loading: false,
      rosterWorks: [],
      total: 0,
      form: 0,
      to: 0,
      masterKey: 0,
      query: {
        name: "",
        search: "",
        id: "",
        page: 1,
        per_page: 50,
        status: 0,
      },
      logedInUser: JSON.parse(getLogedInUser()),
      fields: [
        "SL",
        "date",
        "employee",
        "company",
        "in",
        "out",
        "shift_hours",
        // "m",
        // "e",
        // "n",
        "ot_1",
        "ot_2",
        "total_hours",
        "status",
      ],
    };
  },
  created() {
    this.getRosterWorkList();
  },

  methods: {
    async getRosterWorkList(e) {
      this.loading = true;

      if (e && typeof e === "number") {
        this.query.page = e;
      } else {
        this.query.page = 1;
      }
      
      this.query.customer_id = this.logedInUser.id;

      await this.$store
        .dispatch("roster/getRosterbyId", this.query)
        .then((response) => {
          if (response.status_code == 200) {
            this.rosterWorks = response.data.data;
            this.loading = false;
            this.total = response.data.total;
            this.form = response.data.from;
            this.to = response.data.to;
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.header-title {
  /* 32 medium */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 43px;
  /* identical to box height */

  /* Text main */

  color: #515b60;
}

.approval-card {
  border-radius: 24px;
}

button {
  margin: 0px 3px;
}
.send {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
  color: white;
}

.discard {
  background: rgba(255, 79, 93, 0.1);
  border: 1px solid #ff4f5d;
  border-radius: 5px;
  color: #ff4f5d;
}
.decline-text {
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
}
.summery-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #7190ef;
}
</style>

<!-- <style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style> -->
<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
