<template>
  <div>
      <p class="sister-concerns-title">Our Subsidiary Companies</p>
      <swiper class="swiper-responsive-breakpoints" :options="swiperOptions"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
        <swiper-slide v-for="(company, index) in companies" :key="index">
          <b-link :to="`/company/view/${company.id}`" class="d-flex align-items-center">
            <b-img :src="
              company.avatar &&
                company.avatar.includes('https://ui-avatars.com')
                ? company.avatar
                : $store.state.appConfig.imageBaseUrl + company.avatar
            " fluid style="height: 100px; width: 100%" />
          </b-link>
      </swiper-slide>

      <div slot="pagination" class="swiper-pagination" />
    </swiper>
  </div>
</template>

<script>
import { BImg, BLink } from "bootstrap-vue";
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
    BLink
  },
  data() {
    return {
      companies: [],
      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 50,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    };
  },
  mounted() {
    this.getCompanies();
  },
  methods: {
    getCompanies() {
      this.$store.dispatch("company/getCompaniesAllInfo").then(({ data }) => {
        
        this.companies = data.data;
      });
    },
  },
};
</script>
<style scoped>
.sister-concerns-title {
  /* 32 medium */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 43px;
  /* identical to box height */

  /* Text main */

  color: #515b60;
}

</style>
