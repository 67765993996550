<template>
  <div>
    <component :is="currentRole" />
  </div>
</template>

<script>
import { getLogedInUser } from "@/utils/auth";
import AdminCustomerDashboard from "./admin-customer-dashboard";
import CustomerDashboard from "./customer";
import EmployeeDashboard from "./employee";
export default {
  data() {
    return {
      currentRole: 'EmployeeDashboard',
    };
  },
  components: {
    AdminCustomerDashboard,
    EmployeeDashboard,
    CustomerDashboard,
  },
  created() {
    if (JSON.parse(getLogedInUser()).roles[0].name === 'superadmin' || JSON.parse(getLogedInUser()).roles[0].name === 'admin') {
      this.currentRole = 'AdminCustomerDashboard';
    }
    if (JSON.parse(getLogedInUser()).roles[0].name === 'customer') {
      this.currentRole = 'CustomerDashboard';
    }
    if (JSON.parse(getLogedInUser()).roles[0].name === 'employee') {
      this.currentRole = 'EmployeeDashboard';
    }
  },
};
</script>

<style></style>
