<template>
  <div>
    <b-row>
      <b-col cols="12" lg="12">
        <b-row class="match-height">
          <b-col cols="12">
            <div class="row">
              <div class="col-12">
                <summeryCard :dashboardData="dashboardData" />
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <timeSheetCard />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getLogedInUser } from "@/utils/auth";
import { BCard, BCol, BRow } from "bootstrap-vue";
import customerCalanderVue from "./customer-calander.vue";
import customerList from "./customer-list.vue";
import summeryCard from "./summery-info.vue";
import timeSheetCard from "./time-sheet-card-component.vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    customerCalanderVue,
    customerList,
    summeryCard,
    timeSheetCard,
  },
  data() {
    return {
      logedInUser: JSON.parse(getLogedInUser()),
      dashboardData: {},
    };
  },
  created() {
    this.customerDashboardData();
  },
  methods: {
    // customerDashboardData
    customerDashboardData(item) {
      this.$store
        .dispatch("dashboard/customerDashboardData")
        .then((response) => {
          this.dashboardData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.header-title {
  /* 32 medium */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 43px;
  /* identical to box height */

  /* Text main */

  color: #515b60;
}
</style>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
