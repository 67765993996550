<template>
  <div id="employee-dashboard">
    <b-row>
      <b-col cols="12" lg="12">
        <b-row v-if="logedInUser.register !== 'candidate'" class="match-height">
          <b-col cols="12">
            <summery-card
              :passport="passport"
              :visa="visa"
              :driving="driving"
              :certificate="certificate"
              :hasExpire="hasExpire"
            />
          </b-col>
          <b-col cols="12">
            <employee-roster />
          </b-col>

          <b-col cols="12">
            <timeSheetCard
              @reloadStatus="getRosterWorkList"
              :rosterWorks="rosterWorks"
              v-if="$can('view unapproved timesheet', 'Employee')"
            />
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col>
            <h1 class="text-center">Hello {{ logedInUser.name }}, Welcome to your dashboard</h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getLogedInUser } from "@/utils/auth";
import { BCard, BCol, BRow } from "bootstrap-vue";
import timeSheetCard from "./employee-timesheet-table.vue";
import employeeRoster from "./employee-roster.vue";
import summeryCard from "./summery-info.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    timeSheetCard,
    employeeRoster,
    summeryCard,
  },
  data() {
    return {
      rosterWorks: [],
      logedInUser: JSON.parse(getLogedInUser()),
      query: {
        name: "",
        search: "",
        company: null,
        id: "",
        industry_id: "",
        page: 1,
        per_page: 10,
        status: 0,
      },
      employeeInfo: [],
      passport: {},
      visa: {},
      driving: {},
      certificate: [],
      hasExpire: false,
    };
  },
  mounted() {
    this.getRosterWorkList();
    if (this.logedInUser.register !== 'candidate') {
      this.getEmployee();
    }
  },
  methods: {
    async getRosterWorkList() {
      this.loading = true;
      if (this.logedInUser.roles[0].name === "customer") {
        this.query.customer_id = this.logedInUser.id;
      }
      if (this.logedInUser.roles[0].name === "admin") {
        this.query.user_id = this.logedInUser.id;
      }
      if (this.logedInUser.roles[0].name == "employee") {
        this.query.employee_id = this.logedInUser.id;
      }
      await this.$store
        .dispatch("roster/getRosterbyId", this.query)
        .then((response) => {
          if (response.status_code == 200) {
            this.rosterWorks = response.data.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.loading = false;
        });
    },
    getEmployee() {
      // console.log(this.logedInUser);
      this.$store
        .dispatch("dashboard/employeeDocumentExpire", this.logedInUser.id)
        .then((res) => {
          this.passport = res.data.passport_days;
          this.visa = res.data.visa_days;
          this.driving = res.data.driving_days;
          this.certificate = res.data.certificate_days;
          let arr = [this.passport, this.visa, this.driving];
          let data = arr.find((item) => item.days_left < 90);
          if (data) {
            this.hasExpire = true;
          }
        });
    },
  },
};
</script>
<style scoped>
.header-title {
  /* 32 medium */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 43px;
  /* identical to box height */

  /* Text main */

  color: #515b60;
}
</style>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
