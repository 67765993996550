<template>
  <b-card id="graph" no-body class="card-revenue-budget">
    <b-row class="mx-0">
      <b-col cols="12" class="p-2">
        <div class="row text-left">
          <div v-if="userData.roles[0].name == 'superadmin'" class="col-12 col-md-6">
            <div>
              <b-form-group class="rounded border border-primary">
                <v-select
                  @input="companyWiseEmployeeCount"
                  v-model="selectedCompany"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="companies"
                  placeholder="Select Company"
                />
              </b-form-group>
            </div>
          </div>
          <div v-if="customers.length > 0" class="col-12 col-md-6">
            <b-form-group class="rounded border border-primary">
              <v-select
                @input="customerTimesheetHoursCountMonths"
                v-model="selectedTimesheetMonth"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="customers"
                placeholder="Select Client"
              />
            </b-form-group>
          </div>
          <b-col cols="12" v-if="selectedCompany.id">
            <div class="d-flex">
              <b-form-radio
                class="mr-1"
                v-model="chartType"
                value="line"
                @change="checkBoxFunction(chartType)"
                >Line Chart</b-form-radio
              >
              <b-form-radio
                class="mr-1"
                v-model="chartType"
                value="bar"
                @change="checkBoxFunction(chartType)"
                >Bar Chart</b-form-radio
              >
              <b-form-radio
                class="mr-1"
                v-model="chartType"
                name="some-radios"
                value="area"
                @change="checkBoxFunction(chartType)"
                >Area Chart</b-form-radio
              >
            </div>
          </b-col>
        </div>
      </b-col>

      <b-col cols="12">
        <!-- chart -->
        <vue-apex-charts
          ref="realtimeChart"
          id="revenue-report-chart"
          :type="chartType"
          height="200"
          :options="options"
          :series="series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { $themeColors } from "@themeConfig";
import {
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BFormRadio,
  BRow,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BFormRadio,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selected: "",
      chartType: "line",
      options: {
        chart: {
          stacked: true,
          type: "line",
          toolbar: { show: false },
        },
        grid: {
          padding: {
            top: 0,
            bottom: 0,
          },
          yaxis: {
            lines: { show: true },
          },
        },
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
          textAnchor: "center",
          style: {
            colors: ["#fff"],
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
          },
        },
        stroke: {
          curve: "smooth",
        },
        axisTicks: {
          show: false,
        },
        // legend: {
        //   show: true,
        //   position: "top",
        //   horizontalAlign: "left",
        //   floating: true,
        //   onItemClick: {
        //     toggleDataSeries: false,
        //   },
        // },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -3,
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        colors: [$themeColors.warning, $themeColors.primary],
      },
      series: [
        {
          name: "",
          data: [],
        },
      ],
      // others sections
      companies: [],
      customers: [],
      query: {
        name: "",
        page: 1,
        per_page: 10000,
        location: "",
        industry: null,
        status: "",
        search: "",
        id: "",
      },
      selectedCompany: [],
      selectedTimesheetCompany: [],
      selectedTimesheetMonth: [],
      userData: JSON.parse(localStorage.getItem("userData")),
    };
  },
  created() {
    this.getCompanies(this.query);
    if (this.userData.roles[0].name == "admin") {
      this.companyWiseEmployeeCount();
    }
  },

  methods: {
    checkBoxFunction(item) {
      if (this.selectedCompany) {
        this.companyWiseEmployeeCount();
      }
      if (this.selectedTimesheetMonth.id) {
        this.customerTimesheetHoursCountMonths();
      }
    },

    getCompanies() {
      this.$store
        .dispatch("company/getCompaniesForDropdown", this.query)
        .then(({ data }) => {
          this.companies = data;
        });
    },
    // companyWiseEmployeeCount,
    // customerTimesheetHoursCountMonths,
    // timesheetHoursCount, //this is pie chart
    async companyWiseEmployeeCount() {
      if (this.userData.roles[0].name == "admin") {
        this.selectedCompany.id = this.userData.id;
      }

      let res = await this.$store.dispatch(
        "dashboard/companyWiseEmployeeCount",
        this.selectedCompany.id
      );
      this.getCustomers();
      this.$refs.realtimeChart.updateSeries([
        {
          name: "Last Year ",
          data: res.previous_year.count,
        },
        {
          name: "Current Year",
          data: res.data.count,
        },
      ]);
      this.$refs.realtimeChart.updateOptions({
        xaxis: {
          categories: res.data.month,
          title: {
            text: "Month",
            title: res.data.message,
            align: "left",
          },
        },
        yaxis: {
          data: res.data.count,
          title: {
            text: "Employee-Growth",
          },
        },
      });
    },
    getCustomers() {
      this.query.id = this.selectedCompany.id;
      this.query.user_id = this.selectedCompany.id;
      this.$store
        .dispatch("customer/getCustomersForDropdown", this.query)
        .then((response) => {
          // console.log(response);
          this.customers = response.data;
        });
    },
    async customerTimesheetHoursCountMonths() {
      let res = await this.$store.dispatch(
        "dashboard/customerTimesheetHoursCountMonths",
        this.selectedTimesheetMonth.id
      );
      this.$refs.realtimeChart.updateSeries([
        {
          name: "Last Year ",
          data: res.previous_year.hours,
        },
        {
          name: "Current Year ",
          data: res.data.hours,
        },
      ]);
      this.$refs.realtimeChart.updateOptions({
        xaxis: {
          categories: res.data.month,
          title: {
            text: "Month",
            align: "left",
          },
        },
        yaxis: {
          title: {
            text: "Timesheet-Growth",
          },
        },
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
