<template>
  <b-card no-body class="">
    <b-row>
      <b-col cols="12" class="py-2">
        <div
          class="d-sm-flex justify-content-between align-items-center mb-2 mx-1"
        >
          <h4 class="card-title mb-50 mb-sm-0">Today’s Active Employee Engagement</h4>
        </div>
      </b-col>
      <b-col cols="12">
        <b-row class="d-flex align-items-center mx-1">
          <b-col>
            <b-card class="bg-engaged">
              <div
                class="d-flex flex-row justify-content-center align-items-center"
              >
                <div>
                  <img
                    src="@/assets/images/icons/engaged.png"
                    class="rounded-circle"
                    alt=""
                  />
                </div>
                <div class="mx-2">
                  <p class="m-0 text-engaged">Engaged Employee</p>
                </div>
                <div class="">
                  <p class="m-0 count-engaged">
                    {{ EmployeeEngagement.today_engaged ? EmployeeEngagement.today_engaged : 0 }}
                  </p>
                </div>
              </div>
            </b-card>
            <b-card class="bg-free">
              <div
                class="d-flex flex-row justify-content-center align-items-center"
              >
                <div>
                  <img
                    src="@/assets/images/icons/employee-free.png"
                    class="rounded-circle"
                    alt=""
                  />
                </div>
                <div class="mx-2">
                  <p class="m-0 text-free">Unengaged Employee</p>
                </div>
                <div class="">
                  <p class="m-0 count-free">
                    {{ EmployeeEngagement.today_free ? EmployeeEngagement.today_free : 0 }}
                  </p>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col>
            <b-card class="shadow-none bg-running">
              <b-card-text
                class="d-flex flex-column justify-content-cente align-items-center py-2"
              >
                <div>
                  <img
                    src="@/assets/images/icons/running-job.png"
                    class="rounded-circle"
                    alt=""
                  />
                </div>
                <div>
                  <p class="m-0 running-text">Running Job Post</p>
                </div>
                <div>
                  <p class="m-0 running-count">
                    {{ EmployeeEngagement.running_jobs ? EmployeeEngagement.running_jobs : 0 }}
                  </p>
                </div>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
BCard,
BCardText,
BCol,
BDropdown,
BDropdownItem,
BRow,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardText,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      EmployeeEngagement: [],
    };
  },
  created() {
    this.getEmployeeEngagement();
  },
  methods: {
    async getEmployeeEngagement() {
      let res = await this.$store.dispatch("dashboard/getEmployeeEngagement");      
      this.EmployeeEngagement = res.data;
    },
  },
};
</script>

<style scoped>
.bg-engaged {
  background: #dcf4e4;
  /* shadow 1 */

  box-shadow: 1px 5px 10px rgba(121, 134, 159, 0.1);
  border-radius: 6px;
}

.bg-free {
  background: #e7edff;
  box-shadow: 1px 5px 10px rgba(121, 134, 159, 0.1);
  border-radius: 6px;
}

.text-engaged {
  /* 14 medium */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  color: #50c878;
}

.text-free {
  /* 14 medium */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  color: #7190ef;
}

.count-free {
  /* 32 bold */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #7190ef;
}

.count-engaged {
  /* 32 bold */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #50c878;
}

.bg-running {
  background: #ffedef;
  border-radius: 6px;
}

.running-text {
  /* 16 medium */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  text-align: center;

  color: #e95060;
}

.running-count {
  /* 32 bold */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */

  color: #e95060;
}
</style>
